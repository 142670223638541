export default {
  name: 'industryHighlightText',
  props: {
    headlineText: String,
    bodyText: String
  },
  components: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {}
};