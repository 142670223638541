import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import BackgroundVideo from '@/components/backgroundVideo.vue';
import industryInstructions from '@/components/industryInstructions.vue';
import industryHighlightText from '@/components/industryHighlightText.vue';
import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css';
export default {
  name: 'Construction',
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    industryHighlightText: industryHighlightText,
    mynFeatures: mynFeatures
  },
  methods: {
    hq: function hq() {
      _ElMessageBox.alert(' <div class="center"> <div class="card black"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🏗</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Headquarters</h1> <p> Using Myneral, you can easily view the real-time location of your inventory from anywhere in the world, including head office. With a web-based admin panel you can generate reports tailored to your liking and view them from anywhere.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    receiving: function receiving() {
      _ElMessageBox.alert(' <div class="center"> <div class="card blue"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🏗</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Receiving Dept.</h1> <p>When materials or tools arrive on site, they can easily be registered into the blockchain using the portable MynOne scanner. This creates an unchangeable record on the blockchain creating a detailed record of people interacting with the item.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    siteStore: function siteStore() {
      _ElMessageBox.alert(' <div class="center"> <div class="card"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🏗</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>On-Site Store</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    matReq: function matReq() {
      _ElMessageBox.alert(' <div class="center"> <div class="card purple"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🏗</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Material Request</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    toolStore: function toolStore() {
      _ElMessageBox.alert(' <div class="center"> <div class="card"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🏗</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Tool Store</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    }
  }
};